import LocalLang from './LocalLang';
import 'devbridge-autocomplete';
import $, { data } from 'jquery';

export default class SearchForm {
    constructor() {
        function SuggestController() {
            this.init = function() {
                var timeoutId;

                $('form[data-suggest]:not(.solr-init)').each(function() {
                    var $form = $(this),
                        $searchBox = $form.find('.tx-solr-suggest'),
                        $formAutoComplete;

                    if ($form.find('.tx-solr-autocomplete').length > 0) {
                        $formAutoComplete = $form.find('.tx-solr-autocomplete');
                    } else {
                        $formAutoComplete = $('body');
                    }

                    $form.find('.tx-solr-suggest-focus').focus();

                    $.ajaxSetup({ jsonp: "tx_solr[callback]" });

                    // when no specific container found, use the form as container
                    if ($searchBox.length === 0) {
                        $searchBox = $form;
                    }
                    $searchBox.css('position', 'relative');

                    // Prevent submit of empty search form
                    $form.on('submit', function(e) {
                        if ($form.find('.tx-solr-suggest').val() === '') {
                            e.preventDefault();
                            $form.find('.tx-solr-suggest').focus();
                        }
                    });

                    $form.addClass('solr-init');
                    
                    $form.find('.tx-solr-suggest').devbridgeAutocomplete({
                        serviceUrl: window.suggetSearchUrl,
                        dataType: 'jsonp',
                        paramName: 'tx_solr[queryString]',
                        groupBy: 'category',
                        maxHeight: 1000,
                        appendTo: $formAutoComplete,
                        autoSelectFirst: false,
                        triggerSelectOnValidInput: false,
                        width: '100%',
                        deferRequestBy: 250,
                        minChars: 2,
                        onSearchStart: function (query) {
                            if (query['tx_solr[queryString]'] ?? false) {
                                query['tx_solr[queryString]'] = $.trim(query['tx_solr[queryString]']);
                            }
                        },
                        onSelect: function(suggestion) {
                            // go to link when selecting found result
                            if (suggestion.data.link) {
                                // Open youtube in overlay
                                if (suggestion.data.link.indexOf('https://www.youtube.com') === 0) {
                                    if (typeof openVideoOverlay === 'undefined') {
                                        console.error('TODO: The function `openVideoOverlay` does not exist yet!');
                                    }
                                    openVideoOverlay(suggestion.data.link);
                                } else {
                                    location.href = suggestion.data.link;
                                }
                                // else trigger form submit (do search)
                            } else {
                                $form.trigger('submit');
                            }
                        },
                        formatGroup: function(suggestion, category) {
                            if (category == 'suggestion') {
                                // category = window.solrSuggestLLMap['suggestion'];
                                category = LocalLang.get('suggestion');
                            }
                            return '<div class="autocomplete-group">' + category + '</div>';
                        },
                        transformResult: function(response) {
                            if (!response.suggestions) return { suggestions: [] };
                            var firstSuggestion, result = {
                                suggestions: $.map(response.suggestions, function(count, suggestion) {
                                    if (!firstSuggestion) firstSuggestion = suggestion;
                                    return { value: suggestion, data: { category: 'suggestion', count: count } };
                                })
                            };

                            $.each(response.documents, function(key, value) {
                                var dataObject = value;

                                // var defaultGroup = $form.data('suggest-header') ? $form.data('suggest-header') : 'Top results';
                                var defaultGroup = LocalLang.get('topresults');
                                dataObject.category = defaultGroup;

                                // if a group is set we try to get a label
                                if (dataObject.group) {
                                    dataObject.category = $form.data('suggest-header-' + dataObject.group) ? $form.data('suggest-header-' + dataObject.group) : dataObject.group;
                                }

                                result.suggestions.push(
                                    {
                                        value: firstSuggestion,
                                        data: dataObject
                                    }
                                );
                            });

                            return result;
                        },
                        beforeRender: function(container) {
                            container.css('max-height', $formAutoComplete.data('layer-height-container') ? $form.find($formAutoComplete.data('layer-height-container')).height() : 1000);
                            
                            var allResultstext = $('#searchForm .searchBox').data('textallresults');

                            var submitButton = 
                                '<div class="tx-solr-all-results">' +
                                '   <a href="#" class="textlink linkSubmitSearch">' +
                                        allResultstext +
                                '   </a>' +
                                '</div>';

                            var closeButton = 
                                '<div class="suggestionCloseButton">' +
                                '   <button type="button" name="button" class="closeBtn btn">' +
                                '       <span class="cross"></span>' +
                                '       <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" version="1.1" class="closingCircle" width="48" height="48">' +
                                '           <circle r="23" cy="24" cx="24" stroke="#3dbea2" stroke-width="2" fill="none"></circle>'
                                '       </svg>' +
                                '   </button>' +
                                '</div>';

                            var suggestionsWrap = $(document.createElement('div')).addClass('suggestionsWrap');
                            suggestionsWrap.append(closeButton);

                            var wraps = [
                                /*$(document.createElement('div')).addClass('suggestionsWrap'),*/
                                suggestionsWrap,
                                $(document.createElement('div')).addClass('documentsWrap'),
                                submitButton,
                                /*closeButton*/
                            ];
                            var wrapIndex = -1;

                            container.children().each(function(i, child) {
                                var $child = $(child);
                                if ($child.hasClass('autocomplete-group')) {
                                    wrapIndex++;
                                }
                                wraps[wrapIndex].append($child);
                            });

                            container.children().remove();
                            container.append($(document.createElement('div')).addClass('tx-solr-autosuggest-wrapper'));
                            container.find('.tx-solr-autosuggest-wrapper').append($(document.createElement('div')).addClass('row'));

                            wraps.forEach(function(wrap) {
                                container.find('.tx-solr-autosuggest-wrapper > .row').append(wrap);
                            });

                            container.addClass('tx-solr-autosuggest');
                            container.find('.linkSubmitSearch').on('click', function() {
                                sumbitSearchForm($(this));
                            });

                            container.find('.suggestionCloseButton').on('click', function() {
                                $('.tx-solr-autosuggest').css('display', 'none');
                            });

                            // add active class to container
                            // $searchBox.parent().addClass('autocomplete-active').fadeIn();
                            $form.parent().addClass('autocomplete-active');
                        },
                        formatResult: function(suggestion, currentValue) {
                            // Do not replace anything if there current value is empty
                            if (!currentValue) {
                                return suggestion.value;
                            }
                            var pattern = '(' + $.Autocomplete.utils.escapeRegExChars(currentValue.trim()) + ')';
                            // normal suggestion
                            if (suggestion.data.category === 'suggestion') {
                                return suggestion.value
                                    .replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')
                                    .replace(/&/g, '&amp;')
                                    .replace(/</g, '&lt;')
                                    .replace(/>/g, '&gt;')
                                    .replace(/"/g, '&quot;')
                                    .replace(/&lt;(\/?strong)&gt;/g, '<$1>');
                            } else {
                                var title = LocalLang.get(suggestion.data.type) + ": " + suggestion.data.title
                                    .replace(new RegExp(pattern, 'gi'), '<em>$1<\/em>')
                                    .replace(/&/g, '&amp;')
                                    .replace(/</g, '&lt;')
                                    .replace(/>/g, '&gt;')
                                    .replace(/"/g, '&quot;')
                                    .replace(/&lt;(\/?em)&gt;/g, '<$1>');

                                var imageClass = '';
                                switch(suggestion.data.type) {
                                    case 'knowledge':
                                        imageClass = 'knowledge';
                                        break;
                                    case 'education':
                                        imageClass = 'education';
                                        break;
                                    default:
                                        imageClass = 'default';
                                }

                                var hasImage = suggestion.data.previewImage != '' && suggestion.data.previewImage != undefined
                                var output = 
                                    '<div class="suggestionTextContainer' + (!hasImage ? ' fullWidth' : '') + '">' +
                                    '<div class="category">/ ' + LocalLang.get(suggestion.data.type) + '</div>' +
                                    '<div class="title">' + suggestion.data.title + '</div>' +
                                    '<div class="desc">' + suggestion.data.content + '</div>'+
                                    '</div>';

                                if(hasImage)
                                    output += 
                                        '<div class="suggestionImageContainer">' + 
                                        '<img class="' + imageClass + '" src="' + suggestion.data.previewImage + '" />' +
                                        '</div>'

                                return '<a href="' + suggestion.data.link + '"><div class="content">' + output + '</div></a>';
                            }
                        }
                    // }).on('blur', function() {
                    //     $searchBox.parent().removeClass('autocomplete-active');
                    //     var $box = $(this);
                    //     setTimeout(function() {
                    //         $box.devbridgeAutocomplete('hide');
                    //         $form.parent().removeClass('autocomplete-active');
                    //     }, 200);
                    });
                });

                $('.autocomplete-suggestions').on('click', function(e) {
                    clearTimeout(timeoutId);
                });
            };
        }

        function sumbitSearchForm(btn) {
            if(btn.closest('form').length > 0)
                btn.closest('form').submit();

            $('#searchForm').submit();
        }

        /** solr search autocomplete **/
        var solrSuggestController = new SuggestController();
        solrSuggestController.init();

        $("body").on("tx_solr_updated", function() {
            solrSuggestController.init();
        });

        
        // FacetHandler
        $(document).ready(function() {
            initFacetHandler();

            $('body').on('tx_solr_updated', function() {
                initFacetHandler();
            });
        });

        function initFacetHandler() {
            var FacetHandler = function($el) {
                this.accordion();
            };

            FacetHandler.prototype.accordion = function() {
                $('#facet-accordion .panel-collapse').on('show.bs.collapse', function() {
                    $('#facet-accordion .panel-collapse.collapse.in').collapse('hide');
                });
            };

            var $facetAccordion = $('#facet-accordion');
            if ($facetAccordion.length > 0) {
                $facetAccordion[0].facetHandler = new FacetHandler();
            }

            $('.btnExtendedSearch').on('click', function() {
                $('.extendedSearchContainer').slideToggle(300);
                $('.btnExtendedSearch').toggleClass('open');
            });

            
            $('.btnNewSearch').on('click', function() {            
                if($('.btnExtendedSearch').hasClass('open')) {
                    $('.extendedSearchContainer').slideToggle(300);
                    $('.btnExtendedSearch').removeClass('open');
                }

                $('#searchParam').val();
                $('.tx-solr-q').val('');
                $('.tx-solr-q').focus();
            });
        }

        // OptionFacetController
        function OptionFacetController() {
            var _this = this;

            this.init = function () {
                _this.initToggle();
                _this.initFilter();
            };

            this.initToggle = function () {
                $('.tx-solr-facet-hidden').hide();
                $('a.tx-solr-facet-show-all').click(function() {
                    if ($(this).parent().siblings('.tx-solr-facet-hidden:visible').length == 0) {
                        $(this).parent().siblings('.tx-solr-facet-hidden').show();
                        $(this).text($(this).data('label-less'));
                        $(this).addClass('show-less');
                    } else {
                        $(this).parent().siblings('.tx-solr-facet-hidden').hide();
                        $(this).text($(this).data('label-more'));
                        $(this).removeClass('show-less');
                    }

                    return false;
                });
            }

            this.initFilter = function () {
                var filterableFacets = $(".facet-filter-box").closest('.facet');
                filterableFacets.each(
                    function () {
                        var searchBox = $(this).find('.facet-filter-box');
                        var searchItems = $(this).find('.facet-filter-item');
                        searchBox.on("keyup", function() {
                            var value = searchBox.val().toLowerCase();
                            searchItems.each(function() {
                                var filteredItem = $(this);
                                filteredItem.toggle(filteredItem.text().toLowerCase().indexOf(value) > -1)
                            });
                        });
                    }
                );
            }
        }

        $(document).ready(function () {
            var optionsController = new OptionFacetController();
            optionsController.init();

            $("body").on("tx_solr_updated", function() {
                optionsController.init();
            });
        });
    }
}
