export default class HeaderSearch {
    constructor() {
        this.fetchDomElements();
        this.registerEventListeners();
    }

    fetchDomElements() {
        this.domElements = {
            searchToggleBtn: document.getElementById('toggleSearchBtn'),
            selectionAndSearchContainer: document.getElementById('selectionAndSearchContainer'),
            navToggleBtn: document.querySelectorAll('.menu-col .navbar-toggler'),
            loginToggleBtns: document.querySelectorAll('.loginToggleBtn'),
            toggleMinibasketBtns: document.querySelectorAll('.toggleMinibasketBtn'),
            searchInput: document.getElementById('searchParam'),
        };
    }

    registerEventListeners() {
        this.domElements.searchToggleBtn.addEventListener('click', () => this.onSearchToggleBtnClick());
    }

    onSearchToggleBtnClick() {
        if (document.body.classList.contains('searchOpen')) {

            if(this.domElements.searchInput.value.length == 0) {
                this.close();
                return;
            }
            document.getElementById('searchForm').submit();
            return;
        } else {
            this.open();
        }
    }

    close() {
        this.domElements.selectionAndSearchContainer.classList.remove('open');
        document.body.classList.remove('searchOpen');
    }

    open() {
        this.closeOtherLayers();

        this.domElements.selectionAndSearchContainer.classList.add('open');
        document.body.classList.add('searchOpen');

        this.domElements.searchInput.focus();
    }

    closeOtherLayers() {
        if (document.body.classList.contains('loginOpen')) {
            this.domElements.loginToggleBtns[0].click();
        }

        if (document.body.classList.contains('menuOpen')) {
            this.domElements.navToggleBtn[0].click();
        }

        if (document.body.classList.contains('minibasketOpen')) {
            this.domElements.toggleMinibasketBtns[0].click();
        }
    }
}
