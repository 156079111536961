import URI from 'urijs';

export default class SearchResults {
    constructor() {
        this.registerEventListeners();
        this.handleInit();
    }

    handleInit() {
        this.fetchDomElements();
        this.initFilter();
        this.initToggle();
        this.initPagination();
    }
    
    fetchDomElements() {
        this.domElements = {
            // filterableFacets: document.querySelectorAll('.facet-filter-box').closest('.facet'),
            filterableFacets: $('.facet-filter-box').closest('.facet'),
            // hiddenFacests: document.querySelectorAll('.tx-solr-facet-hidden'),
            hiddenFacests: $('.tx-solr-facet-hidden'),
        };
    }

    registerEventListeners() {
        $(document).on('change', '#selectSortOrder', this.handleChangeOnAjaxifiedUri);
        $(document).on('click', 'a.solr-ajaxified', this.handleClickOnAjaxifiedUri);
        $(document).on('click', 'a.tx-solr-facet-show-all', this.handleClickFacetShowAll);
        $('body').on('tx_solr_updated', () => this.handleInit());
    }

    initFilter() {
        // filterableFacets = $(".facet-filter-box").closest('.facet');
        this.domElements.filterableFacets.each(
            function () {
                var searchBox = $(this).find('.facet-filter-box');
                var searchItems = $(this).find('.facet-filter-item');
                searchBox.on("keyup", function() {
                    var value = searchBox.val().toLowerCase();
                    searchItems.each(function() {
                        var filteredItem = $(this);
                        filteredItem.toggle(filteredItem.text().toLowerCase().indexOf(value) > -1)
                    });
                });
            }
        );
    }

    initToggle() {
        // $('.tx-solr-facet-hidden').hide();
        this.domElements.hiddenFacests.hide();
        // this.domElements.hiddenFacests.style.display = 'none';
    }

    handleClickFacetShowAll() {
        // $('.tx-solr-facet-hidden').hide();
        var $link = $(this);
        if ($link.parent().siblings('.tx-solr-facet-hidden:visible').length == 0) {
            $link.parent().siblings('.tx-solr-facet-hidden').show();
            $link.text($link.data('label-less'));
        } else {
            $link.parent().siblings('.tx-solr-facet-hidden').hide();
            $link.text($link.data('label-more'));
        }

        return false;
    }

    handleChangeOnAjaxifiedUri() {
        var $select = $(this);
        var ajaxType = 7383;
        var uri = new URI($select.val());

        var $solrContainer = $select.closest(".tx_solr");
        var $solrParent = $solrContainer.parent();

        $('body').append('<div class="loader" />');
        uri.addQuery("type", ajaxType);

        $.get(
            uri.href(),
            function(data) {
                $solrContainer.replaceWith(data);
                $('body').trigger('tx_solr_updated');
                $('body').find('.loader').remove();
            }
        );
        return false;
    }

    handleClickOnAjaxifiedUri() {
        var $link = $(this);
        var ajaxType = 7383;
        var uri = new URI($link.data('uri'));

        var $solrContainer = $link.closest(".tx_solr");
        var $solrParent = $solrContainer.parent();

        $('body').append('<div class="loader" />');
        uri.addQuery("type", ajaxType);

        $.get(
            uri.href(),
            function(data) {
                $solrContainer.replaceWith(data);
                // _this.scrollToTopOfElement(solrParent, 50);
                $('body').trigger('tx_solr_updated');
                $('body').find('.loader').remove();
                // history.replaceState({}, null, uri.removeQuery("type").href());
            }
        );
        return false;
    }

    initPagination() {
        var resultList = $('.results-list');
        var paginationList = $('.pagination');
        var nextButton = $('.pagination li.next a');
    
        if(nextButton.length != 0) {
            $('.loadMoreResults').on('click', function() {
                $('body').append('<div class="loader" />');
                let $temp = $('<div />');
                $temp.load(nextButton[0].getAttribute('href'), function() {
                    $(resultList).append($temp.find('.results-list').children());
                    $(paginationList).html($temp.find('.pagination').html());
                    nextButton = $(paginationList).find('li.next a');
                    $('body').find('.loader').remove();

                    if(nextButton.length != 1)
                        $('.loadMoreResults').css('display', 'none');        
                });
            });
        }
        else {
            $('.loadMoreResults').css('display', 'none');
        }
    }
}
