export default class HeaderHelper {
    constructor() {
        this.fetchDomElements();
        this.registerEventListeners();
    }

    fetchDomElements() {
        this.domElements = {
            header: document.getElementById('header'),
        };
    }

    registerEventListeners() {
        window.addEventListener('scroll', () => this.onScroll(), {passive: true});
        window.addEventListener('resize', () => this.onScroll(), {passive: true});
    }

    onScroll() {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            this.domElements.header.classList.add('sticky');
        } else {
            this.domElements.header.classList.remove('sticky');
        }
    }
}
