// This is based on https://web.dev/debug-web-vitals-in-the-field/.
import {getLCP, getFID, getCLS} from 'web-vitals';

export default class WebVitalsDebugging {
    constructor() {
        this.init();
    }

    init() {
        getCLS((metric) => this.sendToAnalytics(metric));
        getFID((metric) => this.sendToAnalytics(metric));
        getLCP((metric) => this.sendToAnalytics(metric));
    }
    
    getSelector(node, maxLen = 100) {
        let sel = '';
        try {
            while (node && node.nodeType !== 9) {
                let part = node.id ? '#' + node.id : node.nodeName.toLowerCase();
                if (node.className && node.className.length) {
                    part += '.' + Array.from(node.classList.values()).join('.');
                }
                if (sel.length + part.length > maxLen - 1) {
                    return sel || part;
                }
                sel = sel ? part + '>' + sel : part;
                if (node.id) break;
                node = node.parentNode;
            }
        } catch (err) {
            // Do nothing...
        }
        return sel;
    }

    getLargestLayoutShiftEntry(entries) {
        return entries.reduce((a, b) => a && a.value > b.value ? a : b);
    }

    getLargestLayoutShiftSource(sources) {
        return sources.reduce((a, b) => {
            const sizeA = a.previousRect.width * a.previousRect.height;
            const sizeB = b.previousRect.width * b.previousRect.height;
            return a.node && sizeA > sizeB ? a : b;
        });
    }

    wasFIDBeforeDCL(fidEntry) {
        const navEntry = performance.getEntriesByType('navigation')[0];
        return navEntry && fidEntry.startTime < navEntry.domContentLoadedEventStart;
    }

    getDebugInfo(name, entries = []) {
        // In some cases there won't be any entries (e.g. if CLS is 0,
        // or for LCP after a bfcache restore), so we have to check first.
        if (entries.length) {
            if (name === 'LCP') {
                const lastEntry = entries[entries.length - 1];
                return {
                    debug_target: this.getSelector(lastEntry.element),
                    event_time: lastEntry.startTime,
                };
            } else if (name === 'FID') {
                const firstEntry = entries[0];
                return {
                    debug_target: this.getSelector(firstEntry.target),
                    debug_event: firstEntry.name,
                    debug_timing: this.wasFIDBeforeDCL(firstEntry) ? 'pre_dcl' : 'post_dcl',
                    event_time: firstEntry.startTime,
                };
            } else if (name === 'CLS') {
                const largestEntry = this.getLargestLayoutShiftEntry(entries);
                if (largestEntry && largestEntry.sources && largestEntry.sources.length) {
                    const largestSource = this.getLargestLayoutShiftSource(largestEntry.sources);
                    if (largestSource) {
                        return {
                            debug_target: this.getSelector(largestSource.node),
                            event_time: largestEntry.startTime,
                        };
                    }
                }
            }
        }
        // Return default/empty params in case there are no entries.
        return {
            debug_target: '(not set)',
        };
    }

    sendToAnalytics({name, value, delta, id, entries}) {
        // Replace with whatever serialization method you prefer.
        // Note: JSON.stringify will likely include more data than you need.
        const debugInfo = this.getDebugInfo(name, entries);
        const body = JSON.stringify({
            name,
            value,
            url: window.location.href,
            ...debugInfo
        });

        // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
        // Send data to our own logging.
        // if (name == 'CLS') {
        //     const url = '/cls_analytics.php';
        //     (navigator.sendBeacon && navigator.sendBeacon(url, body))
        //     || fetch(url, {body, method: 'POST', keepalive: true});
        // }

        // Send data to google analytics.
        // window.ga('send', 'event', {
        //     eventCategory: 'Web Vitals',
        //     eventAction: name,
        //     // The `id` value will be unique to the current page load. When sending
        //     // multiple values from the same page (e.g. for CLS), Google Analytics can
        //     // compute a total by grouping on this ID (note: requires `eventLabel` to
        //     // be a dimension in your report).
        //     eventLabel: id,
        //     // Google Analytics metrics must be integers, so the value is rounded.
        //     // For CLS the value is first multiplied by 1000 for greater precision
        //     // (note: increase the multiplier for greater precision if needed).
        //     eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
        //     // Use a non-interaction event to avoid affecting bounce rate.
        //     nonInteraction: true,
        //     // Use `sendBeacon()` if the browser supports it.
        //     transport: 'beacon',

        //     // OPTIONAL: any additional params or debug info here.
        //     // See: https://web.dev/debug-web-vitals-in-the-field/
        //     // dimension1: '...',
        //     // dimension2: '...',
        //     // ...
        //     dimension1: debugInfo.debug_target,
        // });

        window.gtag('event', name, {
            event_category: 'Web Vitals',
            event_label: id,
            value: Math.round(name === 'CLS' ? delta * 1000 : delta),
            non_interaction: true,
            transport: 'beacon',
            dimension1: debugInfo.debug_target,
        });
    }
}
