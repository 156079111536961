export default class HeaderMiniBasket {
    constructor() {
        this.fetchDomElements();
        this.registerEventListeners();

        if (this.domElements.minibasket.dataset.openOnload) {
            this.onToggleMinibasketBtnClick();
        }
    }

    fetchDomElements() {
        this.domElements = {
            toggleMinibasketBtns: document.querySelectorAll('.toggleMinibasketBtn'),
            minibasket: document.getElementById('minibasket'),
            minibasketBackdrop: document.getElementById('minibasketBackdrop'),
            navToggleBtn: document.querySelectorAll('.menu-col .navbar-toggler'),
            loginToggleBtns: document.querySelectorAll('.loginToggleBtn'),
            searchToggleBtn: document.getElementById('toggleSearchBtn'),
            continueShoppingBtn: document.querySelectorAll('#minibasket .continueShoppingBtnContainer .btn')[0],
        };
    }

    registerEventListeners() {
        this.domElements.toggleMinibasketBtns.forEach((element) => {
            element.addEventListener('click', () => this.onToggleMinibasketBtnClick())
        });
        this.domElements.minibasketBackdrop.addEventListener('click', () => this.onToggleMinibasketBtnClick());
        if (this.domElements.continueShoppingBtn) {
            this.domElements.continueShoppingBtn.addEventListener('click', () => this.onToggleMinibasketBtnClick());
        }
    }

    onToggleMinibasketBtnClick() {
        if (document.body.classList.contains('loginOpen')) {
            this.domElements.loginToggleBtns[0].click();
        }

        if (document.body.classList.contains('menuOpen')) {
            this.domElements.navToggleBtn[0].click();
        }

        if (document.body.classList.contains('searchOpen')) {
            this.domElements.searchToggleBtn.click();
        }

        this.domElements.minibasket.classList.toggle('open');
        document.body.classList.toggle('minibasketOpen');
    }
}
