export default class HeaderLoginHelper {
    constructor() {
        this.fetchDomElements();
        this.registerEventListeners();
    }

    fetchDomElements() {
        this.domElements = {
            loginToggleBtns: document.querySelectorAll('.loginToggleBtn'),
            navToggleBtn: document.querySelectorAll('.menu-col .navbar-toggler'),
            loginModal: document.getElementById('loginModal'),
            searchToggleBtn: document.getElementById('toggleSearchBtn'),
            toggleMinibasketBtns: document.querySelectorAll('.toggleMinibasketBtn'),
        };
    }

    registerEventListeners() {
        this.domElements.loginToggleBtns.forEach((element) => {
            element.addEventListener('click', (e) => this.onheaderLoginBtnClick(e));
        });

        $(document).on('hide.bs.modal','#loginModal', function () {
            if (document.body.classList.contains('menuOpen')) {
                this.domElements.navToggleBtn[0].click();
            }

            document.body.classList.remove('loginOpen');
        });
    }

    onheaderLoginBtnClick(e) {
        e.preventDefault();
        window.scroll(0,0);

        if (document.body.classList.contains('menuOpen')) {
            this.domElements.navToggleBtn[0].click();
        }

        if (document.body.classList.contains('minibasketOpen')) {
            this.domElements.toggleMinibasketBtns[0].click();
        }

        if (document.body.classList.contains('searchOpen')) {
            this.domElements.searchToggleBtn.click();
        }

        document.body.classList.toggle('loginOpen');
    }
}
