const LL = {
    'de': {
        // 'pages': 'Sonstiges',
        // 'tx_news_domain_model_news': 'Wissen',
        // 'tx_wesnewsextend_domain_model_author': 'Autor',
        // 'tx_wesconsultants_domain_model_company': 'Dienstleister',
        // 'tx_wesconsultants_domain_model_expert': 'Fachexperte',
        // 'oxarticles': 'Shop',
        // 'wekaevents': 'Weiterbildung',
        'knowledge': 'Wissen',
        'shop': 'Shop',
        'education': 'Weiterbildung',
        'misc': 'Sonstiges',
        'suggestion': 'Vorschläge',
        'topresults': 'Top-Treffer',
    },
    'fr': {
        // 'pages': 'Divers',
        // 'tx_news_domain_model_news': 'Savoir',
        // 'tx_wesnewsextend_domain_model_author': 'Auteur',
        // 'tx_wesconsultants_domain_model_company': 'Prestataire de service',
        // 'tx_wesconsultants_domain_model_expert': 'Expert',
        // 'oxarticles': 'Shop',
        // 'wekaevents': 'Formation',
        'knowledge': 'Savoir',
        'shop': 'Shop',
        'education': 'Formation',
        'misc': 'Divers',
        'suggestion': 'Propositions',
        'topresults': 'Meilleurs résultats',
    },
};

let lang;

export default class LocalLang {
    static get(key) {
        if (!lang) {
            lang = document.getElementsByTagName('html')[0].lang;
        }
        return LL[lang][key] || 'LL key "' + key + '" undefined.';
    }
}
