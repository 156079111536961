export default class Utils {
    constructor() {
        this.passiveEventhandlerSupported = null;
    }

    isPassiveEventHandlerSupported() {
        if (this.passiveEventhandlerSupported !== null) {
            return this.passiveEventhandlerSupported;
        }

        try {
            const options = {
                get passive() { // This function will be called when the browser
                                //   attempts to access the passive property.
                    this.passiveEventhandlerSupported = true;
                    return false;
                }
            };

            window.addEventListener("test", null, options);
            window.removeEventListener("test", null, options);
        } catch(err) {
            this.passiveEventhandlerSupported = false;
        }

        return this.passiveEventhandlerSupported;
    }

    /* CC-BY-SA stackoverflow user joachim-isaksson: http://stackoverflow.com/a/17907562 */
    getInternetExplorerVersion() {
        var rv = -1;
        var ua, re;
        if (navigator.appName == 'Microsoft Internet Explorer') {
            ua = navigator.userAgent;
            re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
            if (re.exec(ua) !== null)
                rv = parseFloat( RegExp.$1 );
        } else if (navigator.appName == 'Netscape') {
            ua = navigator.userAgent;
            re  = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
            if (re.exec(ua) !== null)
            rv = parseFloat( RegExp.$1 );
        }
        return rv;
    }

    isIE() {
        return this.getInternetExplorerVersion() != -1;
    }

    isEdge() {
        return /Edge\/\d+/.test(navigator.userAgent);
    }

    getGoogleChromeVersion() {
        if (this.isEdge()) {
            return -1;
        }

        var version = -1;
        if (navigator.userAgent.indexOf('Chrome') != -1) {
            var userAgent = navigator.userAgent;
            var regEx     = new RegExp("Chrome/([0-9]{1,})");
            var result    = userAgent.match(regEx);
            if (result !== null) {
                version = parseInt(result[1]);
            }
        }
        return version;
    }

    isGC() {
        return this.getGoogleChromeVersion() != -1;
    }

    getiOSVersion() {
        const agent = window.navigator.userAgent;
        const start = agent.indexOf('OS');

        if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1 || agent.indexOf('iPod') > -1) && start > -1) {
            return window.Number(agent.substr(start + 3, 3).replace('_', '.'));

        } else if (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1) {
            let matches = window.navigator.userAgent.match(/Version\/(\d+)/);
            if (!matches) return -1;

            return parseInt(matches[1]);
        }
        return -1;
    }

    isiOS() {
        return this.getiOSVersion() != -1;
    }
}
