export default class AboModelSticky {
    constructor(sliders) {
        this.sliders = sliders;
        this.fetchElements();
        this.fetchVars();
        this.container.css('top', 0);
        this.registerEventHandler();
        $('.aboContent .swiper-wrapper').css('cursor', 'default');
    }

    fetchElements() {
        this.header = $('#header');
        this.container = $('.stickyContainer');
        this.swiper = $('.aboModels .aboModelSwiper');
        this.slide = $('.stickyContainer .swiper-slide');
        //this.slide = $('.swiper-slide');
        /*
        this.bestDeal = $('.bestDeal');
        this.articleImage = $('.articleImage');
        this.headlinePrice = $('h3.price');
        this.headlineTitle = $('h3.title');
        this.headlineTitleSpan = $('h3.title span');
        this.descripton = $('.desc');
        this.button = $('.swiper-slide button');
        */
        this.checklistContainer = $('.checklistContainer');

        /*
        const aboSlider = document.querySelectorAll('.aboModelSwiper');
        aboSlider.forEach(function() {
            console.log($(this).activeIndex);
        });
        */
    }

    fetchVars() {
        this.animPos1 = 500;
        this.animPos2 = 700;
        this.animPos3 = 900;
        this.animPos4 = 1000;
        this.lastYPos = 0;
        this.duration = 300;;
        this.offset = this.header.height();
    }

    registerEventHandler() {
        var self = this
        
        $(document).ready(function() {
            self.init()
        });

        document.addEventListener('scroll', function(e) {
            self.onScroll(e);
        });
        
        var self = this;
        this.sliders.forEach(slider => {
            slider.swiper.on('activeIndexChange', function (elm) {
                var index = elm.activeIndex;
                self.sliders.forEach(slider => {
                    slider.swiper.slideTo(index, 0, null);
                });
            });
        });

        // $('.aboContent .swiper-wrapper').on('click', function(e) {
        //     e.preventDefault();
        // })
    }

    init() {       
        this.top = this.swiper.offset().top;
        this.onScroll(0);
    }

    onScroll(e) {
        if(window.scrollY > this.lastYPos) {
            this.collapse();

        }
        else if(window.scrollY < this.lastYPos) {
            this.expand();
        }   

        this.lastYPos = window.scrollY;
    }

    collapse() {
        if(window.scrollY > this.top - this.offset && !this.header.hasClass('header-collapse'))
            this.header.addClass('header-collapse');

        if(window.scrollY > this.top && this.container.css('display') != 'block') {
            this.container.css('display', 'block');
            this.swiper.css('opacity', 0);

            var margin = window.screen.width > 1200 ? '-250px' : '-100px';
            $('.aboContent .swiper-wrapper').animate({'margin-bottom': margin}, {queue: false, duration: this.duration});
        }

        if(window.scrollY > this.animPos1 && !this.slide.hasClass('clps-1')) {
            this.slide.addClass('clps-1');
        }

        if(window.scrollY > this.animPos2 && !this.slide.hasClass('clps-2')) {
            this.slide.addClass('clps-2');
        }

        if(window.scrollY > this.animPos3 && !this.slide.hasClass('clps-3')) {
            var self = this;
            this.checklistContainer.slideUp({duration: this.duration, complete: function() {
                self.slide.addClass('clps-3');
            }});
        }

        if(window.scrollY > this.animPos4 && !this.slide.hasClass('clps-4')) {
            this.slide.addClass('clps-4');
        }
    }

    expand() {
        if(window.scrollY < this.top - this.offset && this.header.hasClass('header-collapse'))
            this.header.removeClass('header-collapse');

        if(window.scrollY < this.top && this.container.css('display') != 'none') {
            this.container.css('display', 'none');
            this.swiper.css('opacity', 1);
            $('.aboContent .swiper-wrapper').css('margin-bottom', '0');
        }

        if(window.scrollY < this.animPos1 && this.slide.hasClass('clps-1')) {
            this.slide.removeClass('clps-1');
        }

        if(window.scrollY < this.animPos2 && this.slide.hasClass('clps-2')) {
            this.slide.removeClass('clps-2');
        }

        if(window.scrollY < this.animPos3 && this.slide.hasClass('clps-3')) {
            var self = this;
            this.checklistContainer.slideDown({duration: this.duration, complete: function() {
                self.slide.removeClass('clps-3');
            }});
        }

        if(window.scrollY < this.animPos4 && this.slide.hasClass('clps-4')) {
            this.slide.removeClass('clps-4');
        }
    }
}
