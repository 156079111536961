export default class HeaderNavigation {
    constructor() {
        $('span.fe.fe-chevron-right').on('transitionend', function(e) {
            e.stopPropagation()
        });

        var $navOverlayContainer = $('#navOverlayContainer');
        var navContainer = $('#mainnav');
        var $mainNavListContainer = $('#mainnavListContainer');
        var nav = $('#mainnavList');
        var $mainnavToggler = $('.menu-col .navbar-toggler');
        var $body = $('body');
        var levels = 3;
        var targetLevel;
        var activeLevel;
        var activeMenu;
        var currentLevel;
        var $activeMenuPoint;

        // set initial menu state
        mobileNavigationSetInitState();

        // set events for changing between levels
        for (var i = 2; i <= levels; i++) {
            // open ith level
            $('#mainnav .level'+(i-1)+' > li.hasSub:not(.backLinkContainer) > a').click(mobilenavigationOpenLevelHandler(i));

            // close ith level
            $('#mainnav .level'+i+' > li.backLinkContainer > .prevLevel').click(mobilenavigationCloseLevelHandler(i));
        }

        // handle classes and height after transition
        nav.on('transitionend', function() {
            for (var i = 2; i <= levels; i++) {
                if (navContainer.hasClass('openingLevel'+i)) {
                    navContainer.removeClass('openingLevel'+i).addClass('openLevel'+i);
                }

                if (navContainer.hasClass('changingLevel'+i)) {
                    $('#mainnav .level'+(i-1)+' > li.open').removeClass('open');
                    $('#mainnav .level'+(i-1)+' > li.openAfterChange').addClass('open').removeClass('openAfterChange');
                    navContainer.removeClass('changingLevel'+i);
                }

                if (!navContainer.hasClass('openLevel'+i)) {
                    $('#mainnav .level'+(i-1)+' > li.open').removeClass('open');
                }

                setMenuHeight();
            }
        });

        $navOverlayContainer.on('shown.bs.collapse', function() {
            setMenuHeight();
        });

        // reset on menu closed
        $('.headerMainContainer, #mobileNavigationOverlay').click(function() {
            if($('#pageContainer').hasClass('active')) {
                for(var i = 2; i <= levels; i++) {
                    navContainer.removeClass('openLevel'+i);
                    $('#mainnav .level'+(i-1)+' > li.open').removeClass('open');
                }

                // reset back to initial state
                mobileNavigationSetInitState();
            }
        });

        var loginToggleBtn = document.querySelectorAll('.loginToggleBtn')[0];
        var toggleMinibasketBtn = document.querySelectorAll('.toggleMinibasketBtn')[0];
        var searchToggleBtn = document.getElementById('toggleSearchBtn');
        var contentnavActiveLink;

        $mainnavToggler.on('click', function() {
            // if ($body.hasClass('loginOpen')) {
            //     loginToggleBtn.click();
            //     $loginModal.on('hidden.bs.modal'), function() {
            //         $body.toggleClass('fixed menuOpen');
            //     }
            // } else {
            //     $body.toggleClass('fixed menuOpen');
            // }
            if ($body.hasClass('loginOpen')) {
                loginToggleBtn.click();
            }

            // if ($body.hasClass('searchOpen')) {
            //     searchToggleBtn.click();
            // }
            window.headerSearch.close();

            if ($body.hasClass('minibasketOpen')) {
                toggleMinibasketBtn.click();
            }

            contentnavActiveLink = document.querySelector('#maincategoryTagList .tagLink.active');

            if (contentnavActiveLink) {
                setTimeout(function() {
                    contentnavActiveLink.click();
                    $body.addClass('fixed menuOpen');
                }, 400);
            } else {
                $body.toggleClass('fixed menuOpen');
            }
        });

        function setMenuHeight() {
            var $currentLevel = $('li.open', nav).last().children('ul');
            var currentLevelHeight = $currentLevel.height();

            if ($currentLevel.length == 0) {
                // $mainNavListContainer.css('max-height', '');
                $mainNavListContainer.css('height', 'auto');

            } else {
                // $mainNavListContainer.css('max-height', currentLevelHeight);
                $mainNavListContainer.css('height', currentLevelHeight);
            }

            if($('html').hasClass('ios')){
                if(!$('header').hasClass('iosFixApplied')){
                    $('header').hide().show(0);
                    $('header').addClass('iosFixApplied');
                }

                $("body").prepend('<div id="redraw" style="pointer-events:none;position:fixed;top:0;left:0;right:0;bottom:0;z-index:99999;background-color:transparent;"></div>');
                setTimeout(function(){
                    $('#redraw').remove();
                }, 50);
            }
        }

        function mobilenavigationOpenLevelHandler(level) {
            return function(event) {
                event.preventDefault();
                event.stopPropagation();
                mobileNavigationOpenLevel(level, $(this));
            };
        }

        function mobilenavigationCloseLevelHandler(level) {
            return function(event) {
                event.preventDefault();
                event.stopPropagation();
                mobileNavigationCloseLevel(level, $(this));
            };
        }

        // open given level
        function mobileNavigationOpenLevel(level, self) {
            currentLevel = $('li.open', nav).length + 1;
            if (level <= currentLevel) {
                for (var i = currentLevel; i > level; i--) {
                    mobileNavigationCloseLevel(i, $(this));
                }
                if (!self.parent().hasClass('open')) {
                    self.parent().addClass('openAfterChange');
                    setTimeout(function(){
                        navContainer.addClass('changingLevel' + level);
                    }, 100);
                }
            } else {
                self.parent().addClass('open');
                setTimeout(function() {
                    navContainer.addClass('openingLevel' + level);
                }, 0);
            }
        }

        // close given level
        function mobileNavigationCloseLevel(level, self) {
            navContainer.removeClass('openLevel' + level);
        }

        // set init state of menu
        function mobileNavigationSetInitState() {
            activeMenu = $('li.active', nav).last().parent('ul');
            activeLevel = $('li.active', nav).length;
            $activeMenuPoint = $('li.active', nav).last();

            $('li.active', nav).each(function(i) {
                if ($activeMenuPoint.hasClass('hasSub')) {
                    if(i < activeLevel) {
                        $(this).addClass('open');
                    }
                } else {
                    if(i < activeLevel - 1) {
                        $(this).addClass('open');
                    }
                }
            });

            if(activeLevel) {
                if ($activeMenuPoint.hasClass('hasSub')) {
                    for (var j = 2; j <= activeLevel + 1; j++) {
                        navContainer.addClass('openLevel' + j);
                    }
                } else {
                    for (var j = 2; j <= activeLevel; j++) {
                        navContainer.addClass('openLevel' + j);
                    }
                }
            }
        }

        function mobileNavHeight() {
            activeMenu = $('li.open', nav).last().children('> .');
        }
    }
}
