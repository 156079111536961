export default class ReviveHelper {
    constructor() {
        this.timeoutId = null;

        this.fetchDomElements();

        if (this.domElements.scripts.length == 0) return;

        this.registerEventHandler();
        this.checkWindowSizeAndActivateRevive();
    }

    fetchDomElements() {
        this.domElements = {
            scripts: document.querySelectorAll('script[data-revive-src]'),
        };
    }

    registerEventHandler() {
        // We have to create the function this way, or we are not able to remove
        // the event listener later...
        this.onResizeOrOrientationchange = () => {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => this.checkWindowSizeAndActivateRevive(), 200);
        };
        
        window.addEventListener('resize', this.onResizeOrOrientationchange);
        window.addEventListener('orientationchange', this.onResizeOrOrientationchange);
    }

    checkWindowSizeAndActivateRevive() {
        if ($(window).width() <= 767) return;
        
        let s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = this.domElements.scripts[0].dataset.reviveSrc;
        document.body.append(s);

        window.removeEventListener('resize', this.onResizeOrOrientationchange);
        window.removeEventListener('orientationchange', this.onResizeOrOrientationchange);
    }
}